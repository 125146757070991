<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Add to Template</h1>
                            <button 
                                @click="closeModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="modal-body">
                    <div class="pb-2">
                        <div class="row">
                            <div class="col-12 mb-1">
                                <label class="form-label">Template Title</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model.trim="formData.title"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Template Title"
                                    />
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <SubmitCancelBtn
                                    class="text-center"
                                    @onSubmit="submit"
                                    @onCancel="closeModal"
                                    :loading="loading"
                                    :disabled="!Boolean(formData.title)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';
import handleHospital from '@/services/modules/hospital'

const { storePrescriptionTemplate } = handleHospital();

const props = defineProps({
    doctorOrderId: {
        type: Number,
        required: false
    },
    onSubmit: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['onSubmit']);

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore();
const route = useRoute();
const loading = ref(false);
const formData = ref({
    company_id: route.params.companyId,
    title: '',
    status: 'active'
});

const closeModal = () => {
    store.state.isModalOpenThree = false;
}

const submit = async () => {

    loading.value = true

    await storePrescriptionTemplate(formData.value, props.doctorOrderId).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        closeModal();
        emit('onSubmit', true)
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}
</script>

<style scoped>
.med-title {
    padding: 0.486rem 1.5rem;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>