<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                @onClickCloseButton="onClickToClose"
                title="Write Prescription"
            >
                <template #extra-btn>
                    <button
                        v-if="doctorOrder && !doctorOrder.template_logs_exists"
                        @click="onClickToMakeTemplate"
                        class="btn btn-primary waves-effect waves-float waves-light"
                    >Make template
                    </button>
                    <button
                        v-if="doctorOrder && doctorOrder.template_logs_exists"
                        class="btn btn-outline-secondary waves-effect waves-float waves-light"
                        @click="removeTemplate"
                        :disabled="loadingTwo"
                    >Cancel Template
                    </button>
                    <div class="template-selection" v-if="!doctorOrder">
                        <label class="form-label">Select Template</label>
                        <AsyncSelect
                            placeholder="Select Template"
                            v-model="template"
                            class="w-100"
                            :api-service="fetchPrescriptionTemplates"
                        />
                    </div>
                </template>
            </TitleButton>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row justify-content-between" v-if="service.service_resource">
                    <div class="col-4 col-md-4 doctor-details-bn">
                        {{ service.service_resource.short_description }}
                    </div>

                    <div class="col-4 col-md-4 doctor-details-en text-right">
                        {{ service.service_resource.full_description }}
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 patient-details">
                <div class="row" v-if="service.contact_profile">
                    <div class="col-6 col-md-6">
                        <div class="name-seg">
                            <b>Name: {{ service.contact_profile.full_name }}</b>
                        </div> 
                    </div>
                    <div class="col-3 col-md-3">
                        <div class="name-seg">
                            <b>Age & Sex: &nbsp;</b>
                            <b>{{ toCapitalizeFirst(service.contact_profile.gender) }}({{ getAge(service.contact_profile.birthday) }} years)</b> 
                        </div>
                    </div>
                    <div class="col-3 col-md-3">
                        <div class="date-seg">
                            <b>Date: </b>
                            <b>18/09/2023</b> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-3 col-md-4 col-lg-4">

                <div class="row note-list">
                    <div v-for="(note, index) in notes" :key="index" class="col-12 col-md-12 note-section">
                        <RxNote
                            v-if="note.value == 'investigation'"
                            :note="note" :index="index"
                            @openModal="openModal"
                        />
                        <AdmissionNote
                            v-else
                            :note="note" :index="index"
                            @openModal="openModal"
                            :isPhc="note.value === 'phc_note'"
                        />
                    </div>
                </div>
                
            </div>
            

            <div class="col-12 col-sm-8 col-md-8 col-lg-8 medicine-seg">

                    <div class="row rx-notes">

                        <div v-for="(note, index) in rxList" :key="index" class="col-12 col-md-12 note-section">

                            <RxNote
                                v-if="note.value === 'rx'"
                                :note="note" :index="index" 
                                @openModal="openModal"
                                :medicineNotes="medicineNotes"
                            />

                            <AdmissionNote
                                :note="note" v-else
                                :index="index" @openModal="openModal"
                            />

                        </div>
                        
                    </div>

            </div>

            <div class="col-12 footer-section">
                <button type="button" @click="submit" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save
                </button>
            </div>

        </div>

        <AddNoteModal
            v-if="store.state.isModalOpen"
            :note="singleNote"
        /> 

        <AddRxNoteModal
            v-if="store.state.isModalOpenTwo"
            :note="singleNote"
        />

        <AddTempleModal 
            v-if="store.state.isModalOpenThree"
            :doctorOrderId="doctorOrder ? doctorOrder.id : ''"
            @onSubmit="changeTemplateStatus"
        />

        <Loader v-if="loader"/>
    </div>
</template>

<script setup>

    import TitleButton from '@/components/atom/TitleButton';
    import AdmissionNote from '@/components/molecule/company/hospital/note/Note.vue';
    import RxNote from '@/components/molecule/company/hospital/note/RxNote.vue';
    import handleHospital from '@/services/modules/hospital'
    import Loader from "@/components/atom/LoaderComponent";
    import AddNoteModal from '@/components/molecule/company/hospital/note/AddNoteModal.vue';
    import AddRxNoteModal from '@/components/molecule/company/hospital/note/AddRxNoteModal.vue';
    import handleCompany from "@/services/modules/company";
    import writePrescriptionHelper from '@/services/utils/pdf/writePrescriptionHelper';
    import AddTempleModal from '@/components/molecule/company/hospital/prescription/AddTempleModal.vue';
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import { inject, onMounted, reactive, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { toCapitalizeFirst, dateOfBirthConvertToAge, json_string_parse, convertToTitleCase } from '@/services/utils/global'
    import { computed } from 'vue';

    const $router = useRouter();
    const $route = useRoute();
    const store = useStore();
    const { 
        fetchSinglePrescription, 
        storePrescription, 
        updatePrescription, 
        updateTemplateStatus,
        fetchPrescriptionTemplates,
        fetchTemplatePrescription
    } = handleHospital();
    const { fetchCompanyInfo } = handleCompany();
    const { generatePdf } = writePrescriptionHelper();

    const singleNote = ref(null);
    const notes = reactive([])
    const rxList = reactive([]);
    const medicineNotes = reactive([]);
    const loading = ref(false);
    const loadingTwo = ref(false);
    const loader = ref(false);
    const service = ref({});
    const companyInfo = ref({});
    const template = ref(null);

    const doctorOrder = computed(() =>  {
        if(template.value) return;
        return service.value.doctor_order && service.value.doctor_order;
    });
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');


    const getAge = (birthday) => {
        if(!birthday) return '';
        const {year} = dateOfBirthConvertToAge(birthday);
        return year;
    }

    const onClickToClose = () => {
        $router.push({name: 'prescription-doctor',
            params: $route.params,
            query: $route.query,
        })
    }

    const changeTemplateStatus = (isExists) => {
        if(! service.value.doctor_order) return;
        service.value.doctor_order.template_logs_exists = isExists;
    }

    const removeTemplate = async () => {
        const formData = {
            status: 'inactive',
            company_id: $route.params.companyId
        }

        loadingTwo.value = false
        await updateTemplateStatus(formData, doctorOrder.value.id).then((res) => {
            loadingTwo.value = false
            if (!res.status) return showError(res.message);
            changeTemplateStatus(false);
            return showSuccess(res.message);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loadingTwo.value = false
        })
    }

    const onClickToMakeTemplate = () => {
        store.state.isModalOpenThree = true;
    }

    const submit = async () => {
        
        const formData = formateFormData();
        const doctorOrderId = doctorOrder.value ? doctorOrder.value.id : '';
        const prescriptionAction = doctorOrderId ? updatePrescription : storePrescription

        loading.value = true

        await prescriptionAction(formData, doctorOrderId).then((res) => {
            loading.value = false
            if (!res.status) return showError(res.message);
            goToPrint();
            onClickToClose();
            return showSuccess(res.message);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    const goToPrint = () => {
        const serviceId = $route.params.serviceId;
        const query = getQuery();
        const isHideFooter = true;
        const isHideHeader = true;
        
        fetchSinglePrescription(query, serviceId, 'opd_prescription').then((res) => {
            if (!res.status) return showError(res.message)
            service.value = res.data.service
        }).then(() => {
            generatePdf(companyInfo.value, notes, rxList, service.value, isHideHeader, isHideFooter)
        })
    }

    const formateFormData = () => {
        const {rx, investigation, other_notes} = formatNotes();
        const formData = {
            rx_notes: rx,
            investigations: investigation,
            other_notes: other_notes,
            contact_profile_id: service.value.contact_profile_id,
            service_general_id: service.value.id,
            service_resource_id: service.value.service_resource_id,
            company_id: $route.params.companyId,
            prescription_type: 'opd_prescription',
        }
        return formData;
    }

    const formatNotes = () => {
        const noteObj = {
            rx: [],
            investigation: [],
            other_notes: [],
        };

        const allNotes = [...notes, ...rxList].filter(item => item.selectedNotes.length);

        for(const note of allNotes){
            if(noteObj.hasOwnProperty(note.value)){
                noteObj[note.value].push(...note.selectedNotes);
                if(note.newNotesByDoctor.length){
                    noteObj.other_notes = noteObj.other_notes.concat(setMedicineData(note.newNotesByDoctor));
                }
                continue;
            }
            noteObj['other_notes'].push({
                id: note.id ? note.id : null,
                type : note.value,
                note : JSON.stringify(note.selectedNotes),
                new_notes: note.newNotesByDoctor,
            });
        }

        return noteObj;
    }

    const setMedicineData = (notes) => {
        return notes.map(note => {
            const newNote = [ note.name ];
            return {
                id: note.id,
                type : note.type,
                note : JSON.stringify(newNote),
                new_notes: newNote
            }
        })
    }

    const openModal = ({note, index}) => {
        singleNote.value = null;
        singleNote.value = {
            index: index,
            name: note.name,
            value: note.value,
            selectedNotes: note.selectedNotes,
            newNotesByDoctor: note.newNotesByDoctor,
            preAddedNotes: json_string_parse(note.preAddedNotes),
            noteMasters: note.noteMasters
        }

        if(['rx', 'investigation'].includes(note.value)){
            store.state.isModalOpenTwo = true;
            return;
        }
        
        store.state.isModalOpen = true;
    }

    const getQuery = () => {
        let query = `?company_id=${$route.params.companyId}`;
        return query;
    }

    const setDoctorOrderData = () => {

        setPHCData();

        const doctorOrder = service.value.doctor_order;
        
        if(!doctorOrder) return;

        if(doctorOrder.medicine_orders.length){
            setMedicineOrderData(doctorOrder.medicine_orders, 'rx');
        }
        
        if(doctorOrder.investigation_orders.length){
            setMedicineOrderData(doctorOrder.investigation_orders, 'investigation');
        }

        if(doctorOrder.notes.length){
            setNoteData(doctorOrder.notes);
        }
    }

    const setPHCData = () => {
        
        const phcNote = notes.find(item => item.value === 'phc_note');
        if(!phcNote) return;

        phcNote.isHideAddBtn = true;

        const phcObj = service.value.phc_general;
        if(!phcObj) return;
        
        const excludedList = ['blood_group', 'blood_group_name', 'type_of_weight_name', 'blood_pressure_types_name']
        const phcNoteList = [];
        let phcNoteText = '';

        for(const key in phcObj){
            if(excludedList.includes(key)) continue;

            const phcItem = {
                name: key === 'bmi' ? key.toUpperCase() : convertToTitleCase(key),
                value: phcObj[key] ? phcObj[key] : ''
            };

            phcNoteList.push(phcItem)
            phcNoteText += `${phcItem.name}: ${phcItem.value} \n`;
        }
       
        phcNote.phcNoteList = phcNoteList
        phcNote.phcNoteText = JSON.stringify(phcNoteText);
    }

    const setNoteData = (other_notes) => {
        for(let noteItem of other_notes){
            let prevNote = rxList.find(item => item.value === noteItem.type);
            if(!prevNote){
                prevNote = notes.find(item => item.value === noteItem.type);
            }

            prevNote.selectedNotes = [];

            prevNote.id = noteItem.id;

            if(prevNote.value === 'phc_note') continue;
            prevNote.selectedNotes.push(...noteItem.note);
        }
    }

    const setMedicineOrderData = (medicineRx, noteType) => {
        let prevNote = rxList.find(item => item.value === noteType);
        if(!prevNote){
            prevNote = notes.find(item => item.value === noteType);
        }

        prevNote.selectedNotes = [];

        for(let note of medicineRx){
            const noteObj = {
                id: note.id,
                product_id: note.product_id,
                name: note.product.name,
                remarks: note.remarks,
                isSelected: true,
            };

            if(noteType === 'rx'){
                noteObj['dose'] = note.dose;
                noteObj['unit'] = note.unit;
                noteObj['duration'] = note.duration;
                noteObj['madication_status'] = note.madication_status;
            }

            prevNote.selectedNotes.push(noteObj)
        }
    }

    const getTemplatePrescription = async () => {
        const query = getQuery();

        await fetchTemplatePrescription(query, template.value.templateable_id).then((res) => {
            if (!res.status) return showError(res.message)
            service.value.doctor_order = res.data;
            setDoctorOrderData();
        });
    }

    const resetPrescription = () => {
        service.value.doctor_order = null;

        notes.forEach(item => {
            item.selectedNotes = [];
        });

        rxList.forEach(item => {
            item.selectedNotes = [];
        });
    }

    const getSinglePrescription = async () => {
        const serviceId = $route.params.serviceId;
        const query = getQuery();

        await fetchSinglePrescription(query, serviceId, 'opd_prescription').then((res) => {
            if (!res.status) return showError(res.message)
            notes.push(...res.data.prescriptionLeftSideNotes)
            rxList.push(...res.data.prescriptionRightSideNotes)
            medicineNotes.push(...res.data.rxNotes)
            service.value = res.data.service;
        });
    }

    const fetchInitialData = async () => {
        
        loader.value = true;

        await Promise.all([
            getSinglePrescription(),
            fetchCompanyInfo($route.params.companyId).then((res) => {
                companyInfo.value = res.data
            })
        ]).then(() => {

            setDoctorOrderData();
            loader.value = false;
            
        }).catch((err) => {
            loader.value = false
        });
    }

    watch(template, (newValue, oldValue) => {
        if(newValue) {
            getTemplatePrescription();
        }
        
        if(!newValue && oldValue){
            resetPrescription();
        }
    });

    onMounted(() => {
        fetchInitialData();
    })

</script>

<style scoped>
.template-selection {
    margin-left: -97%;
    width: 166%;
}
.note-section {
    margin-top: 2%;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    width: 96%;
    margin-left: 2%;
}
.footer-section{
    text-align: center;
    padding: 3% 0;
}
.date-seg {
    text-align: right;
}
.profile-search {
    width: 70%;
}
.profile-search input{
    padding: 0.471rem 1rem;
    line-height: 1;
}
.profile-search-action button{
    padding: 0.486rem 0.5rem;
}
.profile-head{
    display: flex;
    justify-content: space-between;
    left: 7%;
    position: absolute;
}
.note-list{
    margin-left: 0%;
    margin-top: 2%;
}
.medicine-seg{
    border-left: 1px solid black;
    min-height: 800px;
}
.rx-notes{
    padding-right: 1rem;
}
.patient-details{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 94%;
    padding: 1% 0;
    margin-left: 30px;
}
.specialty{
    color: #d0494e;
}
.name{
    color: #6a6f96;
}
.name-seg{
    display: flex;
    align-items: center;
}
.qualification{
    color: #54d4be;
}
.doctor-details-en {
    padding-right: 3%;
    padding-top: 2%;
}

.doctor-details-bn {
    padding-left: 3%;
    padding-top: 2%;
}
</style>